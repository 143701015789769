import { initReactI18next } from 'react-i18next';


import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';

import { ruTrans, enTrans } from './shared/locales';

const LANGUAGES = { RU: 'ru-RU', EN: 'en-EN'}; // This initialization happens before our constants are initialized

const resources = {
  [LANGUAGES.RU]: {
    translation: ruTrans,
  },
  [LANGUAGES.EN]: {
    translation: enTrans,
  },
  
};

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
    
  .init({
    resources,
    debug: false,
    supportedLngs: [LANGUAGES.RU, LANGUAGES.EN],
    fallbackLng: LANGUAGES.RU,
    interpolation: {
      escapeValue: false,
    },
    load: 'currentOnly',
    detection: {
      order: ['localStorage', 'navigator'],
    },
  });
  // .init({
  //   fallbackLng: 'ru-RU',
  //   supportedLngs: ['en-EN', 'ru-RU'], // Укажите полные коды языков
  //   debug: true,
  //   nonExplicitSupportedLngs: true,
  //   interpolation: {
  //     escapeValue: false, // React already escapes by default
  //   },
  //   resources: {
  //     'ru-RU': ruTrans,
  //     'en-EN': enTrans
  //   }
  // });

  if (typeof window !== 'undefined') {
    (window as any).i18n = i18n;
  }

export default i18n;
