import React, { FC, useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next'; // <-- Импорт i18next

import i18n from 'i18next'; // Импорт i18next для смены языка

import LanguageIcon from '@mui/icons-material/Language'; // Иконка для кнопки выбора языка
import {
  Container,
  TextField,
  Stack,
  Button,
  FormHelperText,
  Grid,
  Menu,
  MenuItem,
  IconButton,
} from '@mui/material';

import { useLogin } from './login.actions';
import { FormInputs } from './login.types';

import styles from './login.module.scss';

const LoginPage: FC = () => {
  const { t } = useTranslation(); // <-- Хук для получения переводов
  const [response, handleLoginSubmit, loading, loginError] = useLogin();

  const {
    handleSubmit,
    formState: { errors },
    setError,
    control,
    setValue, // Для программного задания значения ключа
  } = useForm<FormInputs>();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  // Открытие меню выбора языка
  const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  // Закрытие меню выбора языка
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  // Смена языка
  const handleLanguageChange = (lng: string) => {
    i18n.changeLanguage(lng); // Сменить язык
    setAnchorEl(null); // Закрыть меню
  };

  // Автоматически обрабатывать ключ из URL и отправлять форму
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const key = urlParams.get('key');

    if (key) {
      setValue('key', key); // Устанавливаем значение ключа в форму
      handleSubmit(onSubmit)(); // Автоматически отправляем форму
    }
  }, [setValue, handleSubmit]);

  const onSubmit = (data: FormInputs) => {
    handleLoginSubmit(data);
  };

  // return (
  //   <Container
  //     sx={{
  //       display: 'flex',
  //       flexGrow: 1,
  //       flexDirection: 'column',
  //       justifyContent: 'center',
  //     }}
  //     maxWidth="xl"
  //   >
  //     <form onSubmit={(...args) => void handleSubmit(onSubmit)(...args)}>
  //       <Grid className={styles.gridCenterItems} container item xs={12}>
  //         <Grid item xs={12} sm={10} md={6}>
  //           <Stack className={styles.gridAlignItems} spacing={2}>
  //             <Controller
  //               name="key"
  //               defaultValue=""
  //               control={control}
  //               rules={{ required: true }}
  //               render={({ field }) => (
  //                 <TextField
  //                   {...field}
  //                   className={styles.loginInput}
  //                   error={!!errors?.key}
  //                   label={t('login.enterKey')} // <-- Используем перевод
  //                 />
  //               )}
  //             />
  //             {errors.root && errors.root.loginError && (
  //               <FormHelperText error>{loginError.message}</FormHelperText>
  //             )}
  //             <Button
  //               className={styles.btnLogin}
  //               disabled={loading}
  //               size="small"
  //               type="submit"
  //               variant="contained"
  //             >
  //               {t('login.login')}
  //             </Button>
  //           </Stack>
  //         </Grid>
  //       </Grid>
  //     </form>
  //   </Container>
  // );
  // };

  return (
    <Container
      sx={{
        display: 'flex',
        flexGrow: 1,
        flexDirection: 'column',
        justifyContent: 'center',
      }}
      maxWidth="xl"
    >
      {/* Кнопка выбора языка */}
      <div
        style={{
          position: 'absolute',
          top: 10,
          right: 10,
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <span style={{ marginRight: 8, fontSize: 18 }}>
          {t('global.language')}
        </span>{' '}
        <IconButton onClick={handleOpenMenu}>
          <LanguageIcon style={{ fontSize: 36 }} />{' '}
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleCloseMenu}
        >
          <MenuItem onClick={() => handleLanguageChange('en-EN')}>
            English
          </MenuItem>
          <MenuItem onClick={() => handleLanguageChange('ru-RU')}>
            Русский
          </MenuItem>
        </Menu>
      </div>

      <form onSubmit={(...args) => void handleSubmit(onSubmit)(...args)}>
        <Grid className={styles.gridCenterItems} container item xs={12}>
          <Grid item xs={12} sm={10} md={6}>
            <Stack className={styles.gridAlignItems} spacing={2}>
              <Controller
                name="key"
                defaultValue=""
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    className={styles.loginInput}
                    error={!!errors?.key}
                    label={t('login.enterKey')} // Используем перевод
                  />
                )}
              />
              {/* {errors.root && errors.root.loginError && (
                <FormHelperText error>{loginError.message}</FormHelperText>
              )} */}
              {loginError.message && (
                <FormHelperText error>{loginError.message}</FormHelperText>
              )}
              <Button
                className={styles.btnLogin}
                disabled={loading}
                size="small"
                type="submit"
                variant="contained"
              >
                {t('login.login')}
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </form>
    </Container>
  );
};

export default LoginPage;
