import { useCallback } from 'react';

import useAxios from '@services/api';

import { CreateKeyFormInputs } from './CreateKeyForm/create-key-form.types';
import type { GameKeyRow } from './master.types';

export const useFetchGameKeys = () => {
  const { response, error, issueRequest } = useAxios<GameKeyRow[]>();

  const fetchGameKeys = useCallback(() => {
    void issueRequest({ url: `/admin/games/keys`, method: 'get' });
  }, []);

  return [response, fetchGameKeys] as const;
};

export const useDeleteGameKey = () => {
  const { response, error, issueRequest } = useAxios<Record<string, never>>();
  
  const deleteGameKey = useCallback((id: string) => {
    return issueRequest({ url: `/admin/games/keys/${id}`, method: 'delete' });
  }, []);

  return [response, deleteGameKey] as const;
};

export const useUpdateGameKeyField = () => {
  const { response, error, issueRequest } = useAxios<Record<string, never>>();

  const updateGameKey = useCallback(
    (id: string, body: Partial<CreateKeyFormInputs>) => {
      return issueRequest({
        url: `/admin/games/keys/${id}`,
        method: 'put',
        body,
      });
    },
    []
  );

  return [response, updateGameKey] as const;
};

export const useExportGameKeys = () => {
  const { response, error, issueRequest } = useAxios();

  const exportGameKeys = useCallback((limit: string) => {
    return issueRequest({
      url: `/admin/games/keys/export?limit=${limit}`,
      method: 'get',
      responseType: 'blob',
    });
  }, []);

  return [response, exportGameKeys] as const;
};
